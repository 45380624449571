// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-customize-js": () => import("./../src/pages/customize.js" /* webpackChunkName: "component---src-pages-customize-js" */),
  "component---src-pages-customize-mastering-js": () => import("./../src/pages/customize_mastering.js" /* webpackChunkName: "component---src-pages-customize-mastering-js" */),
  "component---src-pages-customize-production-js": () => import("./../src/pages/customize_production.js" /* webpackChunkName: "component---src-pages-customize-production-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-imprint-js": () => import("./../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-upload-js": () => import("./../src/pages/upload.js" /* webpackChunkName: "component---src-pages-upload-js" */)
}

